import {
  Button,
  DatePicker,
  Row,
  Col,
  Space,
  Divider,
  Table,
  Form,
  Select,
  Flex,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";
import { API } from "../utils/Constants";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

function Search() {
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [scraper, setScraper] = useState("shab");
  const [filter, setFilter] = useState("shab");
  const [orders, setOrders] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  let day = new Date();
  const currentDate = `${day.getFullYear()}-${String(
    day.getMonth() + 1
  ).padStart(2, "0")}-${String(day.getDate()).padStart(2, "0")}`;

  const dateFormat = "YYYY-MM-DD";

  const fetchData = () => {
    axios
      .get(API + "/orders/" + localStorage.getItem("username"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        params: {
          page: tableParams.pagination.current,
          size: tableParams.pagination.pageSize,
          scraper: scraper,
          start: dateRange.start,
          end: dateRange.end,
        },
      })
      .then((response) => {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.total,
          },
        });

        setOrders(
          response.data.items.map((item) => {
            return {
              ...item,
              finished: item.finished ? (item.error ? <strong style={{color: 'red'}}>Failed</strong> : <strong style={{color: 'green'}}>Completed</strong>) : "Processing...",
              download: (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleDownload(item.id)}
                    disabled={item.error ? item.error : !item.finished}
                  >
                    Download
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleDelete(item.id)}
                    disabled={!item.finished}
                    danger
                  >
                    Delete
                  </Button>
                </Space>
              ),
              key: item.id,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error.response.data.detail);
      });
  };

  const newOrder = (scraper, filter, date) => {
    axios
      .post(
        API + "/order",
        {
          user_id: localStorage.getItem("user_id"),
          scraper: scraper,
          filter: filter,
          date: date.format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error.response.data.detail);
      });
  };

  useEffect(() => {
    fetchData();
    //Implementing the setInterval method
    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [tableParams.pagination.current, scraper, dateRange]);

  const handleSubmit = (values) => {
    newOrder(values.scraper, values.filter, values.date);
  };

  const handleTableChange = (pagination) => {
    setTableParams({ pagination });
  };

  const handleDownload = (order_id) => {
    axios({
      url: API + "/download/" + order_id,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "jobs.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const handleDelete = (order_id) => {
    axios({
      url: API + "/delete/" + order_id,
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }).then((response => {
      fetchData();
    }))
  }

  const handleScraperChange = (value) => {
    setScraper(value);
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleSearch = (value) => {
    const startDate = value.date[0].format("YYYY-MM-DD");
    const endDate = value.date[1].format("YYYY-MM-DD");

    setDateRange({
      start: startDate,
      end: endDate,
    });
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Filter",
      dataIndex: "filter",
      key: "filter",
    },
    {
      title: "Target Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Progress",
      dataIndex: "finished",
      key: "finished",
    },
    {
      title: "",
      dataIndex: "download",
      key: "download",
    },
  ];

  return (
    <div style={{ margin: 10 }}>
      <Row>
        <Col span={6}>
          <Form
            name="order"
            onFinish={handleSubmit}
            initialValues={{
              scraper: "shab",
              filter: "shab",
              date: dayjs(currentDate, dateFormat),
            }}
          >
            <Space
              direction="vertical"
              style={{ width: "100%", paddingRight: 20 }}
            >
              <Divider
                orientation="left"
                orientationMargin={0}
                style={{ marginTop: 0 }}
              >
                Scraper Options
              </Divider>
              <Form.Item name="scraper">
                <Select
                  options={[
                    {
                      value: "shab",
                      label: "Shab.ch",
                    },
                  ]}
                  onChange={handleScraperChange}
                />
              </Form.Item>
              <Divider
                orientation="left"
                orientationMargin={0}
                style={{ marginTop: 0 }}
              >
                Select Filter
              </Divider>
              <Form.Item name="filter">
                <Select
                  options={[
                    {
                      value: "shab",
                      label: "Shab Filter",
                    },
                  ]}
                  onChange={handleFilterChange}
                />
              </Form.Item>
              <Divider
                orientation="left"
                orientationMargin={0}
                style={{ marginTop: 0 }}
              >
                Choose Date
              </Divider>
              <Form.Item name="date">
                <DatePicker style={{width: '100%', borderRadius: 0}} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ borderRadius: 0 }}
                  size="large"
                  block
                >
                  Submit
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Col>
        <Col span={18}>
          <Form name="search" onFinish={handleSearch}>
            <Flex justify="end" gap={10}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <RangePicker
                  style={{ borderRadius: 0 }}
                  maxDate={dayjs(currentDate, dateFormat)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ borderRadius: 0 }}
                >
                  Search
                </Button>
              </Form.Item>
            </Flex>
          </Form>
          <Table
            columns={columns}
            dataSource={orders}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Search;
